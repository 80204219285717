:root {
    --heading-text-color: #171b1f;
    --body-text-color: #3d4852;
    --orange-btn: #ff9326;
    --orange-btn-hover: #e57529;
    --yellow-btn: #fce172;
    --yellow-btn-hover: #eaa213;
    --light-yellow-btn: #fff0de;
    --light-yellow-hover: #ffb973;
    --light-purple-btn: #e8e6ff;
    --light-purple-hover: #cac5ff;
    --dark-yellow-btn: #ffcc33;
    --dark-yellow-btn-hover: #eaa213;
    --light-blue-btn: #edf3fa;
    --light-blue-btn-hover: #b0cceb;
    --btn-color: #003973;
    --white-color: #fff;
    --black-color: #000;
    --blue-gray-color: #627386;
    --blue-color: #0073e6;
    --blue-btn-hover: #0060bf;
    --brown-color: #791e00;
    --navy-color: #09004d;
    --blue-btn-active: #004d99;
    --dark-blue-color: #00264d;
    --dark-green-color: #003a3e;
    --dark-gray-color: #555;
    --blue-gray-color: #637484;
    --pink-btn: #fc7c94;
    --pink-btn-hover: #f1465b;
    --cyan-btn: #bbfffc;
    --cyan-btn-hover: #30e1d0;
    --purple-section: #b3b0f4;
    --purple-section-hover: #a1a2ff;
    --purple-section-content-hover: #f3f2ff;
    --green-section: #8aebe0;
    --green-section-hover: #6edace;
    --green-section-content-hover: #f3fdfb;
    --blue-section: #99ccff;
    --blue-section-hover: #73b9ff;
    --blue-section-content-hover: #f2f9ff;
    --red-section: #fbbec2;
    --red-section-hover: #f99c9f;
    --red-section-content-hover: #fff8f8;
    --default-border-radius: 6px;
    --large-border-radius: 16px;
    --font-size-heading-xx-large: 48px;
    --font-size-heading-extra-large: 40px;
    --font-size-heading-large: 32px;
    --font-size-heading-medium: 24px;
    --font-size-heading-small: 22px;
    --font-size-body-large: 20px;
    --font-size-body-medium: 18px;
    --font-size-body-small: 16px;
    --font-size-body-extra-small: 14px;
    --line-height-xs: 1.1;
    --line-height-sm: 1.2;
    --line-height-md: 1.3;
    --line-height-lg: 1.4;
    --line-height-xl: 1.5;
    --line-height-xxl: 1.6;
    --regular-font: "MetropolisRegular", sans-serif;
    --semi-bold-font: "MetropolisSemiBold", sans-serif;
    --bold-font: "MetropolisBold", sans-serif;
    --extra-bold-font: "MetropolisExtraBold", sans-serif;
}

.red-theme {
    --primary-color: #c11e41;
    --secondary-color: #b5435a;
    --accent-color: #960f39;
    --background-color: #fff8f8;
    --highlight-color: #ffd3d7;
}

.teal-theme {
    --primary-color: #006f7a;
    --secondary-color: #0c7b81;
    --accent-color: #005b60;
    --background-color: #f3fdfb;
    --highlight-color: #8beaea;
}

.purple-theme {
    --primary-color: #5f64c1;
    --secondary-color: #4747a0;
    --accent-color: #1e1a63;
    --background-color: #f3f2ff;
    --highlight-color: #a3a3ef;
}

.orange-theme {
    --primary-color: #f4852a;
    --secondary-color: #bb5511;
    --accent-color: #c33608;
    --background-color: #fff6ef;
    --highlight-color: #ff9326;
}

.gray-theme {
    --primary-color: #e0e9f2;
    --secondary-color: #d0dae4;
    --accent-color: #3d4852;
    --background-color: #edf3fa;
    --highlight-color: #2a3138;
}

.blue-theme {
    --primary-color: #e0e9f2;
    --secondary-color: #d0dae4;
    --accent-color: #3d4852;
    --background-color: #f2f9ff;
    --highlight-color: #0060bf;
}

.btn.border-around-btn {
    border: 3px solid var(--primary-color);
    background-color: var(--white-color);
    color: var(--btn-color);
}

.btn.border-around-btn:active,
.btn.border-around-btn:focus,
.btn.border-around-btn:hover {
    background-color: var(--primary-color);
    color: var(--white-color);
}

.btn.yellow-bg-btn {
    background-color: var(--yellow-btn);
    color: var(--btn-color);
    border-radius: var(--default-border-radius);
    margin-right: 32px;
}

.btn.light-yellow-bg-btn {
    background-color: var(--light-yellow-btn);
    color: var(--brown-color);
    border-radius: var(--default-border-radius);
    margin-right: 32px;
}

.btn.brown-bg-btn {
    background-color: var(--white-color);
    color: var(--brown-color);
    border: 2px solid var(--brown-color);
    border-radius: var(--default-border-radius);
    margin-right: 32px;
}

.btn.brown-bg-btn:active,
.btn.brown-bg-btn:focus,
.btn.brown-bg-btn:hover {
    background-color: var(--brown-color);
    color: var(--white-color);
}

.btn.navy-bg-btn {
    background-color: var(--white-color);
    color: var(--navy-color);
    border: 2px solid var(--navy-color);
    border-radius: var(--default-border-radius);
    margin-right: 32px;
}

.btn.navy-bg-btn:active,
.btn.navy-bg-btn:focus,
.btn.navy-bg-btn:hover {
    background-color: var(--navy-color);
    color: var(--white-color);
}

.theme-box .btn.brown-bg-btn,
.theme-box .btn.navy-bg-btn {
    padding-top: 6px;
}

.btn.orange-bg-btn {
    background-color: var(--orange-btn);
    color: var(--white-color);
    border-radius: var(--default-border-radius);
    font-size: var(--font-size-body-large);
    padding: 8px 46px;
    margin-top: 72px;
}

.btn.light-purple-bg-btn {
    background-color: var(--light-purple-btn);
    color: var(--btn-color);
    border-radius: var(--default-border-radius);
}

.btn.light-purple-bg-btn:active,
.btn.light-purple-bg-btn:focus,
.btn.light-purple-bg-btn:hover {
    background-color: var(--light-purple-hover);
    color: var(--btn-color);
}

.btn.dark-yellow-bg-btn {
    background-color: var(--dark-yellow-btn);
    color: var(--heading-text-color);
    border-radius: var(--default-border-radius);
    margin-right: 32px;
}

.btn.dark-yellow-bg-btn:active,
.btn.dark-yellow-bg-btn:focus,
.btn.dark-yellow-bg-btn:hover {
    background-color: var(--dark-yellow-btn-hover);
    color: var(--white-color);
}

.btn.light-blue-bg-btn {
    background-color: var(--light-blue-btn);
    color: var(--heading-text-color);
    border-radius: var(--default-border-radius);
}

.btn.light-blue-bg-btn:active,
.btn.light-blue-bg-btn:focus,
.btn.light-blue-bg-btn:hover {
    background-color: var(--light-blue-btn-hover);
    color: var(--heading-text-color);
}

.btn.yellow-bg-btn:active,
.btn.yellow-bg-btn:focus,
.btn.yellow-bg-btn:hover {
    background-color: var(--yellow-btn-hover);
    color: var(--white-color);
}

.btn.orange-bg-btn:active,
.btn.orange-bg-btn:focus,
.btn.orange-bg-btn:hover {
    background-color: var(--orange-btn-hover);
    color: var(--white-color);
}

.btn.light-yellow-bg-btn:active,
.btn.light-yellow-bg-btn:focus,
.btn.light-yellow-bg-btn:hover {
    background-color: var(--light-yellow-hover);
    color: var(--brown-color);
}

.btn.pink-bg-btn {
    background-color: var(--pink-btn);
    color: var(--white-color);
    border-radius: var(--default-border-radius);
    margin-right: 32px;
}

.btn.pink-bg-btn:active,
.btn.pink-bg-btn:focus,
.btn.pink-bg-btn:hover {
    background-color: var(--pink-btn-hover);
    color: var(--white-color);
}

.btn.cyan-bg-btn {
    background-color: var(--cyan-btn);
    color: var(--btn-color);
    border-radius: var(--default-border-radius);
    margin-right: 32px;
}

.btn.cyan-bg-btn:active,
.btn.cyan-bg-btn:focus,
.btn.cyan-bg-btn:hover {
    background-color: var(--cyan-btn-hover);
    color: var(--btn-color);
}

.theme-btn.dark-yellow-bg-btn {
    font-family: var(--bold-font);
    margin-right: 0;
}

.classroom-header {
    background: 0 100% / cover url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/classroom-hero-bg.svg) no-repeat;
}

.aware-header {
    background: 0 100% / cover url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/aware-hero-bg.svg) no-repeat;
}

.pass-header {
    background: 0 100% / cover url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/pass-hero-bg.svg) no-repeat;
}

.filter-header {
    background: 0 100% / cover url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/filter-hero-bg.svg) no-repeat;
}

.ipad-header {
    background: 0 100% / cover url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/blue-hero-bg.svg) no-repeat;
}

.digital-header {
    background: 0 100% / cover url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/digital-hero-bg.svg) no-repeat;
    min-height: 636px;
    margin-bottom: 96px;
}

.digital-header .header-logo {
    margin-top: 72px;
}

.digital-header h1 {
    max-width: 80%;
    margin-right: auto;
    margin-left: auto;
}

.digital-hall-pass .extra-bold-heading {
    margin: 96px 0 50px;
}

.bg-testimonial-wrapper.bg-theme {
    background-color: var(--background-color);
    margin-bottom: 98px;
    max-width: 1000px;
    min-height: inherit;
    border-radius: 10px 70px 10px 10px;
}

.bg-testimonial-wrapper.bg-theme .page-carousel {
    padding: 40px 60px 40px 0;
}

.bg-theme:after {
    background: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/classroom-theme-quote.svg) 0 0 no-repeat;
}

.theme-btn {
    background-color: var(--secondary-color);
    color: var(--white-color);
    margin-left: 0;
    position: relative;
    width: inherit;
    padding: 12px 30px;
    height: 50px;
    font-size: var(--font-size-body-large);
}

.theme-btn:active,
.theme-btn:focus,
.theme-btn:hover {
    background-color: var(--accent-color);
    color: var(--white-color);
}

.theme-btn:after {
    content: "";
    position: absolute;
    bottom: -16px;
    right: -16px;
    width: 32px;
    height: 35px;
    background: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/hand-pointer.svg) 0 0 no-repeat;
}

.home-stats.theme-stats .newGrid_container>h2 {
    color: var(--heading-text-color);
    margin: 0 0 64px;
    font: 400 46px/1.2 var(--bold-font);
}

.home-stats.theme-stats h2 {
    color: var(--primary-color);
}

.theme-section .font24 {
    max-width: 68%;
    margin: 0 auto 80px;
    text-align: center;
}

.theme-section .columns-4 {
    display: flex;
    flex-direction: column;
}

.theme-section figure {
    background-color: var(--highlight-color);
    padding: 26px 26px 0;
    height: 176px;
    border-radius: 70px 70px 0 0;
    text-align: center;
}

.theme-section .columns-4 .its-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 28px;
    background-color: var(--background-color);
    font-size: var(--font-size-body-medium);
    line-height: 1.33;
    border-radius: 0 0 16px 16px;
}

.its-content a {
    margin-top: 30px;
    color: var(--secondary-color);
    font-size: var(--font-size-body-medium);
    font-family: var(--bold-font);
    text-decoration: underline;
}

.its-content a:hover,
.its-content a:active,
.its-content a:focus {
    color: var(--accent-color);
}

.theme-section strong {
    display: block;
    margin-bottom: 20px;
}

.theme-section p {
    margin-bottom: 0;
}

.theme-section .image-adjustments figure {
    padding-top: 0;
}

.theme-section .image-adjustments .columns-4:first-child img {
    margin-top: -14px;
}

.theme-section .image-adjustments .columns-4:nth-child(2) img {
    margin-top: -10px;
}

.theme-section .image-adjustments .columns-4:last-child img {
    margin-top: -20px;
}

.theme-box article {
    border-radius: var(--large-border-radius);
    background-color: var(--secondary-color);
    padding: 46px 0 0 46px;
    max-width: 998px;
    margin: 0 auto;
}

.orange-theme article {
    background-color: var(--accent-color);
}

.theme-box .rows,
.theme-box h2 {
    color: var(--white-color);
}

.theme-box h2 {
    font: 400 var(--font-size-heading-large)/var(--line-height-xs) var(--semi-bold-font);
    margin: 0 0 30px;
}

.theme-box h2 strong {
    font-family: var(--extra-bold-font);
    font-size: var(--font-size-heading-extra-large);
}

.flex-end-both {
    justify-self: flex-end;
    align-self: flex-end;
}

.flex-end-center {
    justify-self: flex-end;
    align-self: center;
}

.theme-box p {
    font-size: var(--font-size-heading-small);
    line-height: 1.18;
}

.theme-box .btn {
    margin-bottom: 62px;
    font-size: var(--font-size-body-large);
    padding-top: 8px;
}

.section-with-bg>div {
    padding: 26px 20px;
    border-radius: var(--default-border-radius);
    background-color: var(--background-color);
    margin-bottom: 12px;
    font-size: var(--font-size-body-medium);
    line-height: 1.33;
}

.section-with-bg p {
    margin-bottom: 0;
}

.section-with-bg strong,
.section-with-bg a,
.big-btn strong,
.theme-section strong {
    font-family: var(--bold-font);
}

.section-with-bg strong {
    font-size: var(--font-size-body-large);
}

.section-with-bg a {
    font-size: var(--font-size-body-large);
    color: var(--secondary-color);
    border-bottom: 1px solid;
    display: inline-block;
    margin-top: 14px;
}

.section-with-bg a:active,
.section-with-bg a:focus,
.section-with-bg a:hover {
    color: var(--accent-color);
}

.full-section-box {
    background: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/classroom-works-bg.svg) no-repeat 0 0 / cover;
}

.full-section-box article {
    max-width: inherit;
    background-color: transparent;
    padding: 98px 0 40px 0;
}

.full-section-box ul {
    font-size: var(--font-size-heading-medium);
    padding-left: 20px;
}

.full-section-box ul li::marker {
    color: var(--yellow-btn);
}

.full-section-box img {
    display: inline-block;
    margin: 10px 0 10px 30px;
}

.group-section-icons .rows {
    grid-gap: 14px;
}

.group-section-icons .section-with-bg>div {
    margin-bottom: 0;
}

.group-section-icons .display-flex {
    align-items: flex-start;
    font-size: var(--font-size-body-large);
}

.group-section.group-section-icons .acc-heading {
    max-width: inherit;
}

.group-section-icons img {
    margin-right: 22px;
}

.big-btn {
    font-size: var(--font-size-heading-large);
    line-height: 1;
    word-wrap: inherit;
    color: var(--white-color);
    height: 100px;
    margin: 182px auto 152px;
    border-radius: var(--large-border-radius);
    background-color: var(--secondary-color);
    max-width: 834px;
    padding: 0;
    display: flex;
    align-items: center;
    position: relative;
}

.big-btn img {
    margin: -30px 40px 0 -1px;
}

.big-btn:after {
    content: "";
    position: absolute;
    bottom: -22px;
    right: -22px;
    width: 47px;
    height: 51px;
    background: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/hand-pointer.svg) 0 0 no-repeat;
}

.big-btn:active,
.big-btn:focus,
.big-btn:hover {
    background-color: var(--accent-color);
    color: var(--white-color);
}

.red-theme-explore .learning-section a {
    background-color: var(--background-color);
    min-height: 330px;
}

.teal-theme .learning-section strong {
    min-height: 88px;
}

.justify-flex-end {
    justify-self: flex-end;
}

.theme-header .cloud-page-header-container {
    padding-top: 80px;
}

.simplify-intro .intro-section {
    margin: 168px auto 88px;
}

.section-margin-top {
    margin-top: 140px;
}

.section-margin-top .content {
    margin: 90px 0 150px;
}

.bg-theme .page-testimonial,
.bg-theme strong {
    color: var(--body-text-color);
}

.bg-theme .page-testimonial em,
.bg-theme .page-testimonial .font-size18 {
    font-size: var(--font-size-body-medium);
}

.bg-theme .page-testimonial .font-size18 {
    line-height: 1;
}

.theme-stats {
    padding-bottom: 0;
}

.justify-end {
    justify-content: flex-end;
}

.teal-theme .bg-theme:after {
    background: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/quote-mark.svg) 0 0 no-repeat;
}

.orange-theme .bg-theme:after {
    background: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/orange-quote-mark.svg) 0 0 no-repeat;
}

.purple-theme .bg-theme:after {
    background: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/purple-quote-mark.svg) 0 0 no-repeat;
}

.blue-theme .bg-theme:after {
    background: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/blue-quote-mark.svg) 0 0 no-repeat;
}

.teal-theme .full-section-box {
    background: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/teal-bg.svg) no-repeat 0 0 / cover;
}

.orange-theme .full-section-box {
    background: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/orange-bg.svg) no-repeat 0 0 / cover;
}

.purple-theme .full-section-box {
    background: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/purple-design-bg.webp) no-repeat 0 0 / cover;
}

.blue-theme .full-section-box {
    background: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/blue-design-bg.svg) no-repeat 0 0 / cover;
}

.image-vh-center figure {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 0;
}

.image-vh-center figure.flex-end {
    align-items: flex-end;
}

.element-margin-top {
    margin-top: 98px;
}

.group-section .heading-margin-bottom {
    margin-bottom: 86px;
}

.left-btn-icon {
    padding: 12px 30px 12px 40px;
}

.left-btn-icon:after {
    bottom: -20px;
    right: auto;
    left: -72px;
    width: 88px;
    height: 88px;
    background: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/orange-headphone-icon.svg) 0 0 no-repeat;
}

.left-btn-icon.book-icon:after {
    background: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/orange-book-icon.svg) 0 0 no-repeat;
}

.with-cs-label {
    position: relative;
}

.with-cs-label:after {
    content: "Coming Soon!";
    position: absolute;
    top: -20px;
    right: 20px;
    padding: 8px 20px;
    background-color: #f4852a;
    color: #fff;
    font-size: 14px;
    text-align: center;
    border-radius: 8px 0 8px 0;
    font-style: italic;
}

.cloud-logo {
    margin-top: 50px;
}

.with-purple-bg {
    background-color: var(--background-color);
    border-radius: 10px;
    padding: 20px;
}

.home-latest-stats .with-purple-bg strong {
    font: 400 var(--font-size-heading-medium)/1.67 var(--extra-bold-font);
}

.home-latest-stats .with-purple-bg em {
    font-size: var(--font-size-body-medium);
}

.purple-theme .learning-section strong {
    min-height: 44px;
}

.discern-header {
    background: 0 100% / cover url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/discern-bg.svg) no-repeat;
}

/* Grid Layout */
.grid-container {
    display: grid;
    grid-template-columns: 25% 25% 50%;
    gap: 10px;
    font-size: var(--font-size-body-large);
    line-height: var(--line-height-sm);
    text-wrap: balance;
    max-width: 68%;
    margin: 0 auto;
}

/* Grid Item Base Styling */
.grid-item .grid-heading {
    background-color: var(--accent-color);
    color: var(--white-color);
    font-family: var(--bold-font);
    text-align: center;
    grid-column: span 3;
    margin-bottom: 10px;
    /* Ensures titles span across all columns */
}

.leadership-col .grid-box:not(.grid-heading) {
    background-color: var(--background-color);
    margin-bottom: 10px;
    min-height: 80px;
}

.it-col .grid-box:not(.grid-heading) {
    background-color: var(--primary-color);
    margin-bottom: 10px;
    min-height: 80px;
}

.student-services-col .grid-box:not(.grid-heading) {
    background-color: var(--secondary-color);
    min-height: 80px;
}

.grid-box {
    padding: 16px 10px;
    border-radius: 10px;
    text-align: center;
    color: var(--heading-text-color);
    font-size: var(--font-size-body-large);
    display: flex;
    align-items: center;
    justify-content: center;
}

.grid-item figure.grid-box.grid-img {
    background-color: transparent;
    height: 200px;
    margin-bottom: 30px;
    padding: 0;
}

.student-services {
    grid-column: span 2;
}

.student-columns {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
}

.gray-theme .bg-testimonial-wrapper:after {
    width: 78px;
    height: 64px;
    background: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/gray-quote-mark.svg) 0 0 no-repeat;
}

.gray-theme .bg-testimonial-wrapper {
    background-color: var(--background-color);
}

.gray-theme .bg-testimonial-wrapper .slick-dots li.slick-active button:before {
    background-color: var(--secondary-color);
}

.gray-theme .bg-testimonial-wrapper .slick-dots li button:before {
    border-color: var(--secondary-color);
}

.gray-theme .bg-testimonial-wrapper span {
    margin-bottom: 40px;
    display: block;
}

.shield-section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 14px;
    text-align: center;
    font-size: var(--font-size-body-large);
    line-height: var(--line-height-sm);
    text-wrap: balance;
}

.its-col {
    width: 234px;
    min-height: 322px;
}

.shield-section .its-col a {
    padding: 10px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 234px;
    min-height: 322px;
    position: relative;
    top: 0;
    background: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/shield-holder.webp) 0 0 / contain no-repeat;
}

.shield-section .its-col a:hover,
.shield-section .its-col a:active,
.shield-section .its-col a:focus {
    top: -2px;
}

.shield-section strong {
    color: var(--white-color);
    font-family: var(--bold-font);
    width: 80%;
    margin: 0 auto;
}

.shield-section img {
    margin: 28px 0 0;
}

.shield-section p {
    margin: 50px 0 0;
    color: var(--body-text-color);
}

.theme-section-w-b .columns-4 .its-content {
    background-color: transparent;
}

.theme-section-w-b img {
    margin: 0 auto;
}

.single-text-align .display-flex {
    justify-content: flex-start;
    align-items: center;
}

.orange-bg-block article {
    padding-right: 46px;
    color: #fff;
}

.orange-bg-block .btn {
    margin-top: 0;
    width: 270px;
    padding-top: 7px;
    padding-bottom: 7px;
}

.orange-bg-block .header-logo,
.orange-bg-block {
    margin-bottom: 40px;
}

.orange-bg-block p {
    margin-bottom: 30px;
}

.orange-bg-block strong {
    font-family: var(--bold-font);
}

.orange-bg-block .inline-btns {
    margin-top: 50px;
}

.lausd-header {
    min-height: 740px;
}

.cloud360 .large-font-size {
    font-size: var(--font-size-heading-large);
    margin-bottom: 14px;
}

.inline-btn-text {
    margin-top: 38px;
    text-align: center;
}

.inline-btn-text .btn.orange-bg-btn {
    margin-top: 0;
    padding: 10px 34px;
    font-size: var(--font-size-body-small);
}

.inline-btn-text .btn.yellow-bg-btn {
    margin: 0 0 18px;
}

.inline-btn-text .btn.orange-bg-btn,
.inline-btn-text .btn.yellow-bg-btn {
    width: 296px;
}

.inline-btn-text em {
    display: block;
    font-size: var(--font-size-body-small);
}

.td-format {
    margin-top: 146px;
}

.td-format h2 {
    font: 400 var(--font-size-heading-medium)/var(--line-height-sm) var(--bold-font);
    margin-bottom: 54px;
    text-align: center;
    color: var(--body-text-color);
}

.td-format .section-with-bg {
    display: flex;
    flex-wrap: wrap;
    gap: 14px;
    justify-content: center;
    text-align: center;
}

.td-format .section-with-bg div {
    flex: 0 0 calc(33.33% - 14px);
    margin-bottom: 0;
    font-size: var(--font-size-body-large);
    padding: 12px;
}

.styling p {
    font-size: var(--font-size-heading-medium);
}

.styling strong {
    font-weight: var(--bold-font);
    display: block;
    margin: 40px 0 20px;
}

.styling .btn {
    font-size: var(--font-size-body-small);
    width: 212px;
}

.home-stats.theme-stats .newGrid_container>h2.extra-bold-heading {
    font-family: var(--extra-bold-font);
    font-size: var(--font-size-heading-xx-large);
}

.content-styling strong,
.content-styling p {
    display: block;
    margin-bottom: 20px;
}

.content-styling ul {
    padding-left: 20px;
    list-style: none;
    position: relative;
}

.content-styling ul li::before {
    content: "\2022";
    font-size: 2rem;
    line-height: 0.8;
    color: var(--orange-btn);
    vertical-align: middle;
    position: absolute;
    left: 0;
}

.white-bg-profiles.padding-top-0 {
    padding-top: 0;
}

.blue-color-bg {
    background-color: #0073e5;
    padding: 60px;
    border-radius: var(--large-border-radius);
    margin-bottom: 100px;
}

.blue-color-bg .extra-bold-heading,
.blue-color-bg .large-paragraph {
    color: var(--white-color);
}

.blue-color-bg .extra-bold-heading {
    font-size: var(--font-size-heading-large);
    margin-bottom: 38px;
}

.blue-color-bg strong {
    font-family: var(--bold-font);
}

.blue-color-bg .in-line-btns {
    margin-top: 60px;
    margin-bottom: 0;
}

.uk-index-section {
    margin-bottom: 154px;
}

.uk-index-section img {
    filter: none;
    margin: 0 auto;
}

.uk-index-section a {
    display: flex;
    flex-direction: column;
}

.uk-index-section .middle-desc {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.uk-index-section figure {
    height: 140px;
    border-radius: 70px 70px 0 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    transition: all .1s ease-in;
}

.uk-index-section p {
    flex: 1;
}

.uk-index-section .columns-3:nth-child(1) figure {
    background-color: var(--purple-section);
}

.uk-index-section .columns-3:nth-child(2) figure {
    background-color: var(--green-section);
}

.uk-index-section .columns-3:nth-child(3) figure {
    background-color: var(--blue-section);
}

.uk-index-section .columns-3:nth-child(4) figure {
    background-color: var(--red-section);
}

.uk-index-section .columns-3:nth-child(1):hover figure {
    background-color: var(--purple-section-hover);
}

.uk-index-section .columns-3:nth-child(2):hover figure {
    background-color: var(--green-section-hover);
}

.uk-index-section .columns-3:nth-child(3):hover figure {
    background-color: var(--blue-section-hover);
}

.uk-index-section .columns-3:nth-child(4):hover figure {
    background-color: var(--red-section-hover);
}

.uk-index-section .columns-3:nth-child(1):hover .middle-desc {
    background-color: var(--purple-section-content-hover);
}

.uk-index-section .columns-3:nth-child(2):hover .middle-desc {
    background-color: var(--green-section-content-hover);
}

.uk-index-section .columns-3:nth-child(3):hover .middle-desc {
    background-color: var(--blue-section-content-hover);
}

.uk-index-section .columns-3:nth-child(4):hover .middle-desc {
    background-color: var(--red-section-content-hover);
}

.uk-case-wrapper .columns-3:nth-child(1) a {
    background-image: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/uk-case-study1-bg.webp);
}

.uk-case-wrapper .columns-3:nth-child(2) a {
    background-image: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/uk-case-study2-bg.webp);
}

.uk-case-wrapper .columns-3:nth-child(3) a {
    background-image: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/uk-case-study3-bg.webp);
}

.uk-case-wrapper .columns-3:nth-child(4) a {
    background-image: url(https://stag-web-assets.s3.ap-south-1.amazonaws.com/images/uk-case-study4-bg.webp);
}

.ceo-welcome-msg {
    text-wrap: balance;
    text-align: center;
    margin-bottom: 140px;
}

.ceo-welcome-msg,
.ceo-welcome-msg h2 {
    color: var(--body-text-color);
    font-size: var(--font-size-heading-medium);
    line-height: var(--line-height-sm);
}

.ceo-welcome-msg h2 {
    margin-bottom: 70px;
}

.ceo-welcome-msg strong {
    display: block;
    margin-top: 70px;
}

.ceo-welcome-msg h2,
.ceo-welcome-msg strong {
    font-family: var(--extra-bold-font);
    color: var(--heading-text-color);
}

.ceo-welcome-msg p {
    margin-bottom: 30px;
}

.discover-securly .side-by-side,
.discover-securly .side-by-side h6 {
    color: var(--white-color);
    max-width: inherit;
}

.discover-securly .side-by-side {
    align-items: center;
}

.discover-securly .side-by-side h6 {
    font-size: var(--font-size-body-medium);
    font-family: var(--bold-font);
    margin-bottom: 18px;
}

.discover-securly .side-by-side p {
    font-size: var(--font-size-body-small);
    line-height: var(--line-height-md);
    margin-bottom: 0;
}

.discover-securly h2 {
    font: 400 var(--font-size-heading-large)/var(--line-height-sm) var(--extra-bold-font);
    color: var(--white-color);
    text-align: center;
    margin: 0 0 70px;
}

.discover-securly .side-by-side:hover img.default-image {
    opacity: 1;
}

.discover-securly .side-by-side figure {
    width: 150px;
    flex: 0 0 150px;
    margin-right: 22px;
}

.discover-securly a:hover {
    position: relative;
    top: -2px;
}

.popup-scroll {
    max-height: 400px;
    overflow-y: scroll;
    padding-right: 20px;
}

.text-balance {
    text-wrap: balance;
}

.percent-btns a {
    width: 30%;
    padding-left: 0;
    padding-right: 0;
}

.filled-blue-btn {
    background-color: var(--blue-color);
    color: var(--white-color);
    margin-left: 4%;
}

.filled-blue-btn:hover,
.filled-blue-btn:active,
.filled-blue-btn:focus {
    background-color: var(--blue-btn-hover);
    color: var(--white-color);
}

.dark-blue-border-btn {
    border-color: var(--dark-blue-color);
    color: var(--dark-blue-color);
}

.dark-blue-border-btn:hover,
.dark-blue-border-btn:active,
.dark-blue-border-btn:focus {
    background-color: var(--dark-blue-color);
    color: var(--white-color);
}

.dark-green-border-btn {
    border-color: var(--dark-green-color);
    color: var(--dark-green-color);
    font-size: var(--font-size-body-small);
    padding: 12px 34px;
    margin: 0 10px 10px 0;
}

.dark-green-border-btn:hover,
.dark-green-border-btn:active,
.dark-green-border-btn:focus {
    background-color: var(--dark-green-color);
    color: var(--white-color);
}

.brown-border-btn {
    border-color: var(--brown-color);
    color: var(--brown-color);
    font-size: var(--font-size-body-small);
    padding: 12px 34px;
    margin: 0 10px 10px 0;
}

.brown-border-btn:hover,
.brown-border-btn:active,
.brown-border-btn:focus {
    background-color: var(--brown-color);
    color: var(--white-color);
}

.navy-border-btn {
    border-color: var(--navy-color);
    color: var(--navy-color);
    font-size: var(--font-size-body-small);
    padding: 12px 34px;
    margin: 0 10px 10px 0;
}

.navy-border-btn:hover,
.navy-border-btn:active,
.navy-border-btn:focus {
    background-color: var(--navy-color);
    color: var(--white-color);
}

.dark-blue-fill-btn {
    border-color: var(--btn-color);
    background-color: var(--btn-color);
    color: var(--white-color);
    margin-left: 30px;
}

.theme-box .dark-green-border-btn {
    margin-bottom: 20px;
    padding-top: 11px;
    padding-bottom: 11px;
}

.dark-blue-fill-btn:hover,
.dark-blue-fill-btn:active,
.dark-blue-fill-btn:focus {
    border-color: var(--dark-blue-color);
    background-color: var(--dark-blue-color);
    color: var(--white-color);
}

.marginTop90 {
    margin-top: 90px;
}

.ipad-section {
    margin-bottom: 140px;
}

.ipad-section .extra-bold-heading,
.ipad-section .centered-btn {
    margin-bottom: 0;
}

.ipad-section .columns-6:last-child {
    display: flex;
    align-items: center;
}

.ipad-section.theme-box h2 strong {
    font-size: var(--font-size-heading-large);
}

.ipad-section.theme-box span {
    color: #30e1c3;
}

.ipad-section.theme-box p {
    font-size: var(--font-size-heading-medium);
}

.ipad-section.theme-box .large-font {
    font-family: var(--bold-font);
}

.ipad-section.theme-box .centered-btn {
    margin-top: 70px;
    text-align: left;
}

.ipad-section.theme-box img {
    margin-bottom: -67px;
}

.ipad-header .big-border-btn {
    margin-top: 50px;
}

.text-img {
    margin-left: 30px;
}

.section-margin-top.marginTop0 {
    margin-top: 0;
}

.home-intro.marginBottom0 {
    margin-bottom: 0;
}

.small-inline-btns {
    margin-top: 138px;
    display: flex;
    justify-content: space-between;
}

.small-inline-btns .btn {
    font-size: 12px;
    padding: 6px 12px;
    width: 46%;
    white-space: inherit;
    min-height: 44px;
    text-wrap: balance;
    border-radius: var(--default-border-radius);
    font-family: var(--semi-bold-font);
    display: flex;
    justify-content: center;
    align-items: center;
}

.small-inline-btns .yellow-small-btn {
    background-color: #fbfc34;
    color: var(--dark-blue-color);
}

.small-inline-btns .yellow-small-btn:hover,
.small-inline-btns .yellow-small-btn:active,
.small-inline-btns .yellow-small-btn:focus {
    background-color: #e5e700;
}

.small-inline-btns .white-small-btn {
    background-color: var(--white-color);
    color: var(--dark-blue-color);
    border: 2px solid var(--dark-blue-color);
}

.small-inline-btns .white-small-btn:hover,
.small-inline-btns .white-small-btn:active,
.small-inline-btns .white-small-btn:focus {
    background-color: var(--dark-blue-color);
    color: var(--white-color);
}

.cloud360 h1~p.margin-30 {
    margin: 30px 0;
}

.blue-bg-header .white-btn.blue-btn.margin-top-bottom-10 {
    margin-top: 0;
    margin-bottom: 10px;
}

@media screen and (min-width: 2100px) {
    .blue-bg-footer {
        background-position: bottom;
    }

    .blue-bg-footer>.newGrid_container {
        margin-top: 20px;
    }
}

@media screen and (max-width: 1300px) {
    .text-img {
        display: block;
        margin: 30px 0;
    }
}

@media screen and (max-width: 1200px) {
    .bg-testimonial-wrapper.bg-theme {
        max-width: 750px;
    }

    .theme-header .header-logo {
        margin-top: 30px;
    }

    .bg-testimonial-wrapper.bg-theme .page-carousel {
        padding: 30px 70px 30px 30px;
    }

    .bg-testimonial-wrapper.bg-theme .flex-slide img {
        margin-left: 0;
    }

    .grid-container {
        max-width: inherit;
    }

    .grid-box {
        font-size: var(--font-size-body-extra-small);
    }

    .single-text-align .display-flex {
        display: block;
        text-align: center;
    }

    .single-text-align .display-flex img {
        margin: 0 auto 20px;
        display: block;
    }

    .blue-color-bg {
        padding: 20px;
        text-align: center;
    }

    .blue-color-bg img {
        margin-top: 20px;
    }

    .blue-bg-header .uk-header-slider .cloud-page-header-container {
        padding-top: 30px;
    }

    .discover-securly {
        text-align: left;
    }

    .discover-securly .side-by-side figure {
        width: 100px;
        flex: 0 0 100px;
    }

    .discover-securly img {
        object-fit: cover;
        height: 100px;
    }

    .ipad-section .big-border-btn {
        display: block;
        margin: 0 0 20px;
        width: 240px;
        text-align: center;
    }
}

@media screen and (max-width: 1023px) and (min-width: 767px) {
    .group-section-icons .rows {
        display: grid;
    }

    .in-line-btns .theme-btn {
        width: inherit;
    }

    .uk-header-slider .header-width * {
        text-align: center;
    }

    .blue-color-bg .rows {
        grid-template-columns: repeat(6, 1fr);
    }

    .discover-securly .rows {
        grid-template-columns: repeat(6, 1fr);
        grid-row-gap: 16px;
    }

    .discover-securly h2 {
        margin-bottom: 30px;
    }

    .uk-index-section {
        margin-bottom: 50px;
    }

    .uk-index-section .middle-desc {
        margin-bottom: 20px;
    }

    .blue-color-header.blue-bg-header.lausd-header {
        min-height: 700px;
    }

    .inline-btn-text .rows,
    .styling .rows,
    .ipad-section .rows {
        grid-template-columns: repeat(12, 1fr);
    }

    .ipad-section .centered-btn.marginTop0 {
        margin-top: 0;
    }
}

@media only screen and (min-width: 768px) {
    .d-b-logo {
        display: block;
    }

    .uk-index-section .columns-3 {
        display: flex;
    }

    .inline-btn-text .rows {
        display: flex;
        justify-content: center;
    }
}

@media only screen and (max-width: 1024px) {
    .digital-header .header-logo {
        margin: 30px 0 0;
        width: 300px;
    }

    .theme-stats.pass-lausd-stats .newGrid_container>h2.extra-bold-heading {
        font-size: var(--font-size-heading-large);
    }

    .pass-lausd-stats .flex-section {
        margin-top: 150px;
    }

    .full-section-box.styling article {
        padding-top: 40px;
        padding-bottom: 40px;
    }
}

@media only screen and (max-width: 767px) {
    .shield-section {
        margin-bottom: 50px;
    }

    .discern-big-logo {
        width: 200px;
    }

    .main-mobile {
        color: var(--white-color);
        font-size: var(--font-size-body-extra-small);
        text-align: center;
    }

    .main-mobile p {
        margin: 0 10px;
        position: relative;
    }

    .main-mobile p:first-child {
        top: 50px;
    }

    .main-mobile p:last-child {
        top: -50px;
    }

    .grid-container {
        grid-template-columns: repeat(2, 1fr);
        max-width: inherit;
    }

    .leadership-col .grid-box:not(.grid-heading),
    .it-col .grid-box:not(.grid-heading),
    .student-services-col .grid-box:not(.grid-heading) {
        min-height: 66px;
    }

    .why-love-us.theme-section .its-content:not(.theme-section-w-b .its-content) {
        background-color: var(--background-color);
        border-radius: 16px;
        padding: 20px;
    }

    .gray-theme .bg-testimonial-wrapper:after {
        width: 50px;
        height: 50px;
        background-size: 50px;
        right: 10px;
    }

    .gray-theme .slick-dotted.slick-slider {
        margin-bottom: 10px;
    }

    .gray-theme .btn.dark-yellow-bg-btn {
        font-size: var(--font-size-body-small);
        width: 280px;
        margin: 0 auto;
    }

    .gray-theme .discern-header .btn {
        margin-top: 30px;
    }

    .gray-theme .image-vh-center figure {
        display: none;
    }

    .gray-theme .theme-section .columns-4 .its-content {
        border-radius: 16px;
        padding: 20px;
    }

    .grid-item .grid-heading {
        grid-column: span 2;
    }

    .student-services-col {
        grid-column: span 2;
    }

    .student-columns {
        grid-template-columns: repeat(2, 1fr);
    }

    .theme-box.mobile-theme-box {
        margin: 0 40px 60px;
    }

    .grid-item figure.grid-box.grid-img {
        display: none;
    }

    .theme-box.mobile-theme-box article {
        border-radius: 20px;
    }

    .purple-theme .theme-box .btn {
        margin: 30px auto;
        font-size: var(--font-size-body-small);
        padding: 10px 14px;
    }

    .purple-theme .theme-box img {
        width: 80%;
    }

    .purple-theme .big-btn {
        width: 280px;
    }

    .gray-theme .bg-testimonial-wrapper {
        margin: 60px 0 120px;
        border-radius: 0;
    }

    .theme-header .btn {
        margin-right: 0;
    }

    .big-btn {
        font-size: var(--font-size-body-large);
        margin: 40px auto;
        width: 330px;
        padding: 14px;
        font-size: var(--font-size-body-extra-small);
        height: 50px;
        border-radius: var(--default-border-radius);
    }

    .big-btn div {
        margin: 0 auto;
    }

    .theme-btn:after,
    .big-btn:after {
        display: none;
    }

    .theme-header .seo-header.header-slider .header-logo {
        width: 200px;
        margin-bottom: 10px;
    }

    .theme-header .seo-header.header-slider .filter-logo {
        width: 140px;
    }

    .theme-header .btn.yellow-bg-btn {
        margin: 30px 0;
    }

    .purple-theme .red-in-line-btns .light-teal-btn {
        width: auto;
        display: inline-block;
    }

    .with-purple-bg {
        margin-top: 30px;
    }

    .home-latest-stats .with-purple-bg strong {
        font-size: var(--font-size-body-medium);
        line-height: var(--line-height-sm);
        margin: 0 0 6px;
    }

    .home-latest-stats .with-purple-bg em,
    .its-content a {
        font-size: var(--font-size-body-extra-small);
    }

    .bg-testimonial-wrapper.bg-theme {
        border-radius: 0;
        margin: 100px 0 50px;
    }

    .bg-testimonial-wrapper.bg-theme .page-carousel {
        padding: 20px 20px 80px;
        text-align: center;
    }

    .bg-testimonial-single-wrapper.bg-theme .flex-slide img {
        margin: -70px 0 0;
    }

    .bg-testimonial-wrapper.bg-theme p {
        margin-top: 20px;
    }

    .bg-theme:after,
    .teal-theme .bg-theme:after,
    .orange-theme .bg-theme:after,
    .purple-theme .bg-theme:after,
    .blue-theme .bg-theme:after {
        background-size: 50px;
        right: 20px;
        bottom: 0;
    }

    .red-in-line-btns {
        width: 330px;
        margin: 20px auto;
    }

    .red-in-line-btns .light-teal-btn {
        width: 330px;
        padding: 14px;
        font-size: var(--font-size-body-extra-small);
    }

    .home-stats.theme-stats .newGrid_container>h2,
    .theme-section .eb-heading,
    .two-column-section .eb-heading {
        font-size: var(--font-size-heading-medium);
        margin-bottom: 20px;
    }

    .theme-section .font24 {
        font-size: var(--font-size-body-small);
        max-width: inherit;
        margin: 0 auto 40px;
    }

    .theme-section .columns-4 {
        margin-bottom: 20px;
    }

    .simplify-intro .intro-section {
        margin: 20px 0;
    }

    .section-margin-top {
        margin-top: 40px;
    }

    .theme-box article {
        border-radius: 0;
        padding: 40px 20px 0;
        text-align: center;
    }

    .theme-box h2 {
        font-size: var(--font-size-body-large);
        margin-bottom: 20px;
    }

    .theme-box h2 strong {
        font-size: var(--font-size-heading-medium);
    }

    .flex-end-both {
        justify-self: center;
    }

    .theme-box .btn.yellow-bg-btn,
    .btn.orange-bg-btn {
        margin: 0 0 20px;
        font-size: var(--font-size-body-small);
        padding: 10px 30px;
    }

    .bg-theme .page-testimonial .font-size18,
    .bg-theme .page-testimonial em,
    .section-with-bg a,
    .theme-box p,
    .section-with-bg strong,
    .section-with-bg>div,
    .theme-section .columns-4 .its-content,
    .two-column-section .font24 {
        font-size: var(--font-size-body-small);
    }

    .section-margin-top .content {
        margin: 30px 0;
    }

    .section-with-bg>div {
        padding: 18px 22px;
    }

    .full-section-box ul {
        font-size: var(--font-size-body-small);
        text-align: left;
    }

    .full-section-box img {
        display: block;
        margin: 10px auto;
    }

    .teal-theme .light-teal-btn {
        white-space: inherit;
        height: auto;
    }

    .theme-box .btn {
        margin-right: 0;
    }

    .red-in-line-btns.element-margin-top {
        margin-bottom: 60px;
    }

    .theme-header .cloud-page-header-container {
        padding-top: 40px;
    }

    .theme-header .visible-none {
        margin-right: auto;
        margin-left: auto;
    }

    .group-section .heading-margin-bottom {
        margin-bottom: 40px;
    }

    .left-btn-icon:after {
        display: none;
    }

    .theme-box .yellow-bg-btn.visible-xs {
        margin-top: 30px;
    }

    .pass-header .cloud-page-header-container {
        padding-top: 0;
    }

    .with-cs-label {
        margin-top: 20px;
    }

    .big-btn {
        line-height: var(--line-height-md);
    }

    .digital-header {
        min-height: inherit;
    }

    .digital-header .seo-header.header-slider .header-logo {
        width: 240px;
        margin: 30px 0 0;
    }

    .digital-header h1 {
        max-width: inherit;
    }

    .theme-box .btn {
        margin-right: 10px;
        margin-left: 10px;
    }

    .btn.center-align-btn {
        margin-top: 30px;
    }

    .orange-bg-block .header-logo {
        width: 140px;
        margin-bottom: 20px;
    }

    .theme-box.orange-bg-block article {
        padding-bottom: 30px;
        margin-bottom: 50px;
    }

    .digital-header h1 {
        margin-bottom: 30px;
    }

    .seo-header.header-slider.uk-header-slider .header-width img {
        width: 140px;
        margin-bottom: 20px;
    }

    .uk-index-section .middle-desc {
        padding: 20px;
    }

    .uk-index-section .columns-3:nth-child(1) .middle-desc {
        background-color: var(--purple-section-content-hover);
    }

    .uk-index-section .columns-3:nth-child(2) .middle-desc {
        background-color: var(--green-section-content-hover);
    }

    .uk-index-section .columns-3:nth-child(3) .middle-desc {
        background-color: var(--blue-section-content-hover);
    }

    .uk-index-section .columns-3:nth-child(4) .middle-desc {
        background-color: var(--red-section-content-hover);
    }

    .uk-case-wrapper .columns-3:first-child a {
        background-position: center;
        background-size: cover;
    }

    .blue-color-bg {
        margin: 0 20px 80px;
    }

    .blue-color-bg .extra-bold-heading {
        font-size: var(--font-size-heading-medium);
        margin-bottom: 20px;
    }

    .lausd-header {
        padding-bottom: 80px;
    }

    .pass-lausd-stats .flex-section,
    .td-format {
        margin-top: 30px;
    }

    .td-format .section-with-bg div {
        font-size: var(--font-size-body-small);
    }

    .theme-box.styling h2 strong {
        margin-top: 0;
    }

    .element-styling {
        margin-top: 50px;
    }

    .ceo-welcome-msg,
    .ceo-welcome-msg h2 {
        font-size: var(--font-size-body-large);
    }

    .discover-securly h2 {
        font-size: var(--font-size-heading-medium);
    }

    .ceo-welcome-msg h2,
    .discover-securly h2,
    .discover-securly a,
    .ceo-welcome-msg {
        margin-bottom: 30px;
    }

    .ceo-welcome-msg strong {
        margin-top: 30px;
    }

    .discover-securly .side-by-side figure {
        margin: 0 auto 20px;
    }

    .ipad-section {
        margin-bottom: 50px;
    }

    .ipad-section .extra-bold-heading {
        text-align: center;
    }

    .ipad-section .big-border-btn {
        height: 38px;
        padding: 9px;
        font-size: var(--font-size-body-small);
        width: 200px;
    }

    .percent-btns a.big-border-btn {
        display: block;
        margin-bottom: 20px;
        width: 200px;
    }

    .percent-btns a.big-border-btn:not(.filled-blue-btn) {
        background-color: var(--white-color);
        color: var(--blue-color);
    }

    .seo-header.header-slider .text-img {
        margin: 30px auto;
        width: 230px;
    }

    .ipad-header {
        background-position: 10% 100%;
    }

    .ipad-section.theme-box .centered-btn {
        margin: 20px auto;
        text-align: center;
        width: 200px;
    }

    .ipad-section.theme-box .centered-btn {
        margin: 20px auto;
        width: 200px;
    }

    .ipad-section.theme-box img {
        margin-bottom: -16px;
        width: 60%;
    }

    .blue-theme .red-theme-explore {
        margin-bottom: 30px;
    }

    .small-inline-btns {
        margin-top: 60px;
    }
}

@media screen and (max-width: 768px) and (min-width: 321px) {

    .theme-stats .newGrid_container,
    .two-column-section .newGrid_container,
    .gray-theme .newGrid_container {
        margin: 0 20px;
    }

    .theme-box .newGrid_container {
        margin: 0;
    }
}